<template>
	<div id="foodManage" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>总务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">食材管理</a></el-breadcrumb-item>
			</el-breadcrumb>

      <v-weburl-iframe class="content" url="property/food" />
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import VWeburlIframe from "@/components/weburl-iframe";
export default {
	components: {
    VWeburlIframe,
		index
	},
	data() {
		return {
			gradeMenu: [{ name: '食材入库' }, { name: '食材出库' }],
			gradeIndex: 0,
			List: [],
			addSubject: false,
			loading: false,
			editid: '',
			page: 1,
			limit: 10,
			total: 0,
			showTitle: '添加入库',
			name: '',
			number: '',
			price: '',
			date: '',
			unit:'',
			showType:1
		};
	},
	mounted() {
		this.getInfo();
	},
	methods: {
		closeShow() {
			this.init();
			this.addSubject = false;
		},
		openDialog(type, item) {
			this.showType = type;
			if (type == 1) {
				this.showTitle = '添加入库';
			} else if (type == 2) {
				this.showTitle = '编辑入库';
				this.editid = item.id;
				this.name = item.name;
				this.date = this.$common.timeToDate(item.date);
				this.number = item.number;
				this.price = item.price;
				this.unit = item.unit;
			}
			this.addSubject = true;
		},
		init() {
			this.name = '';
			this.number = '';
			this.price = '';
			this.date = '';
			this.unit = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo();
		},
		twacherTap(index) {
			this.gradeIndex = index;
			if (index == 0) {
				this.$router.push('/foodManage');
			} else if (index == 1) {
				this.$router.push('/stockOut');
			}
		},
		stopDefault(e) {
			e.stopPropagation();
		},
		// 学科信息
		getInfo() {
			this.$api.setting
				.foodMaterialIndex({
					page: this.page,
					limit: this.limit
				})
				.then(res => {
					if (res.data.code == 1) {
						let list = res.data.data.rows;
						this.total = res.data.data.total;
						this.List = list;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init();
			done();
		},
		// 添加学科
		submit() {
			if (this.showType == 1) {
				this.addSubjects();
			} else {
				this.edit();
			}
		},
		addSubjects() {
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入名称');
			}
			if (this.number == '' || !this.number) {
				return this.$message.error('请输入数量');
			}
			if (this.price == '' || !this.price) {
				return this.$message.error('请输入单价');
			}
			if (this.date == '' || !this.date) {
				return this.$message.error('请选择入库时间');
			}
			this.$api.setting
				.addFoodMaterial({
					school_campus_id:window.localStorage.getItem("campusid"),
					name: this.name,
					number: this.number,
					price: this.price,
					date: this.date,
					unit:this.unit
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		edit() {
			if (this.code == '' || !this.code) {
				return this.$message.error('请输入课程代码');
			}
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入课程名称');
			}
			this.$api.setting
				.editFoodMaterial({
					school_campus_id:window.localStorage.getItem("campusid"),
					name: this.name,
					number: this.number,
					price: this.price,
					date: this.date,
					id: this.editid,
					unit:this.unit
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.init();
						this.addSubject = false;
						this.getInfo();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		}
	}
};
</script>

<style lang="scss">
#foodManage {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.6vw;
			}
		}
	}
	.content {
		margin-top: 10px;
		background-color: #ffffff;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table {
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		overflow-y: auto;
		max-height: 600px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
